<template>
  <v-main>
    <!-- <v-img
      :key="src"
      :src="require(`@/assets/${src}`)"
      min-height="100vh"
      :height="$vuetify.breakpoint.mdAndUp ? '100vh' : undefined"
    > -->
      <v-container fluid>
        <v-row  fill-height>
          <v-col :cols="viewcol"  fill-height>
            <router-view :key="$route.path" />
          </v-col>
          <v-col :cols="remain">
            <v-row no-gutters>
              <router-view name="control" />
              <router-view name="mapwidget" />
              <router-view name="version" />
              <router-view name="debugpanel" />
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    <!-- </v-img> -->
  </v-main>
</template>

<script>

import { set, get } from 'vuex-pathify'

  export default {
    name: 'DefaultView',
    data: () => ({
      // src: 'login_background.jpg'
      src: 'birdslife_background.svg'
    }),
    computed: {
      ...get('app', [
        'viewcol'
      ]),
      remain () {
        if (this.viewcol < 12) 
          return 12 - this.viewcol
      }
    }
  }
</script>
